import { StaticQuery, graphql } from 'gatsby';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';

import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import ProjectCard from 'src/components/molecules/ProjectCard/ProjectCard';
import { IAcfModule } from 'src/utils/interfaces';
import { TTheme } from 'src/utils/types';

import styles from './AllProjects.module.scss';
import Loading from 'src/components/atoms/Loading/Loading';

interface IPureAllProjectsProps extends IAllProjectsProps {
  allWordpressWpProjects: any;
}

interface IAllProjectsProps {
  className: string;
  pageTheme: TTheme;
  module: Partial<IAcfModule>;
}

interface IAllProjectsState {
  projects: any;
  currentChunk: number;
  isLoading: boolean;
}

export const PureAllProjects: FunctionComponent<Partial<IPureAllProjectsProps>> =
  memo(({ className, module = {}, allWordpressWpProjects }) => {

    const allProjects: any[] =
      [...allWordpressWpProjects.edges.filter((obj: any) => {
        const { node: project } = obj;
        return project.slug !== '____dumbypost';
      })];

    const [state, setState] = useState<IAllProjectsState>(
      {
        projects: [],
        currentChunk: 1,
        isLoading: true
      }
    );

    useEffect(() => {
      setState(
        {
          ...state,
          projects: getCurrentChunk(allProjects),
          currentChunk: state.currentChunk + 1,
          isLoading: false
        }
      );
    }, []);

    const getCurrentChunk = (array: any): any => {
      return array.slice(0, state.currentChunk * parseInt(process.env.GATSBY_CHUNK_SIZE || '9', 10));
    };

    const loadMoreProjects = (): void => {
      if (!state.isLoading) {
        setState({ ...state, isLoading: true });

        setTimeout((): void => {
          const prevChunk = state.currentChunk;
          const newChunk = state.currentChunk + 1;

          if (state.projects.length === allProjects.length || prevChunk === newChunk) {
            return;
          }

          setState(
            {
              ...state,
              projects: getCurrentChunk(allProjects),
              currentChunk: newChunk,
              isLoading: false
            }
          );

        }, 1e3);
      }
    };

    // if (typeof window !== 'undefined') {
    //   console.log('[AllProjects]', allProjects, allProjects.length);
    // }

    return (
      <Section
        data-test="component-all-projects"
        className={classNames(styles.allProjects, className)}
        theme={module.theme}
      >
        <Container fluid={false} className={styles.allProjectsContainer}>
          <InfiniteScroll
            hasMore={state.projects.length !== allProjects.length}
            loadMore={loadMoreProjects}
            threshold={100}
          >
            <Row>
              {state.projects && state.projects.map((obj: any, index: number) => {
                const { node: project } = obj;

                if (project.slug !== '____dumbypost') {
                  return (
                    <Flex
                      key={index}
                      colXl={4}
                      colLg={6}
                      colMd={6}
                      className={styles.allProjectsProjectWrapper}
                    >
                      <ProjectCard
                        className={classNames(
                          styles.allProjectsProject,
                          `theme--${module.theme}`
                        )}
                        shadow={true}
                        project={project}
                      />
                    </Flex>
                  );
                }

                return null;
              })}
            </Row>

            {state.isLoading && (
              <Row className={classNames(styles.allProjectsLoadingContainer, 'text-center justify-content-center')}>
                <Flex auto>
                  <Loading className={styles.allProjectsLoading} key={'loading'} theme={'tint-psi'}/>
                  <p className={styles.allProjectsCopy}>
                    Loading more content
                  </p>
                </Flex>
              </Row>
            )}
          </InfiniteScroll>
        </Container>
      </Section>
    );
  });

export const AllProjects: FunctionComponent<Partial<IAllProjectsProps>> =
  memo(
    (
      { ...props }
    ) => (
      <StaticQuery
        data-test="component-all-projects-query"
        query={query}
        render={queryProps => <PureAllProjects {...queryProps} {...props} />}
      />
    ));

const query = graphql`
  {
    allWordpressWpProjects(sort: { fields: slug, order: ASC }) {
      edges {
        node {
          id
          wordpress_id
          date
          slug
          status
          title
          yoast_meta {
            ...yoast_meta
          }
          project_services {
            id
            wordpress_id
            name
            slug
          }
          acf {
            subtitle
            page_theme
            website
            logo {
              ...media
            }
          }
          featured_media {
            ...media
          }
        }
      }
    }
  }

  fragment yoast_meta on wordpress__wp_projectsYoast_meta {
    yoast_wpseo_title
    yoast_wpseo_metadesc
    yoast_wpseo_canonical
  }

  fragment media on wordpress__wp_media {
    id
    wordpress_id
    alt_text
    source_url
    media_type
    media_details {
      width
      height
    }
  }
`;

export default AllProjects;

// const alphabet: string[] = [
// 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
// 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
// 't', 'u', 'v', 'w', 'x', 'y', 'z'
// ];
