import { StaticQuery, graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import Heading from 'src/components/shared/Heading/Heading';
import { IAcfModule } from 'src/utils/interfaces';
import { TTheme } from 'src/utils/types';

import styles from './TechnologyBanner.module.scss';

interface IPureTechnologyBannerProps extends ITechnologyBannerProps {
  allWordpressAcfOptions: any;
}

interface ITechnologyBannerProps {
  className?: string;
  pageTheme?: TTheme;
  module?: Partial<IAcfModule>;
}

export const PureTechnologyBanner: FunctionComponent<IPureTechnologyBannerProps> = (
  {
    className,
    pageTheme = 'tint-alpha',
    allWordpressAcfOptions,
    module = {},
  },
) => {
  const { technology } = allWordpressAcfOptions.edges[0].node.options;
  const { theme } = module;

  return (
    <Section
      theme={theme ? theme : pageTheme}
      data-test="component-technology-banner"
      className={classNames(styles.technologyBanner, className)}
    >
      <Container>
        <Row className="justify-content-center">
          <Flex className={classNames(styles.technologyBannerTechHeading, 'align-self-center')}>
            <Heading className={styles.technologyBannerHeading4} priority={4}>
              Tech we use:
            </Heading>
          </Flex>

          {technology && technology.map((tech: any) => {
            const { logo, name } = tech;
            const { id, alt_text, link, media_details } = logo;
            const { width, height } = media_details;

            const imageAttrs = {
              loading: 'lazy'
            };

            return (
              <Flex className={styles.technologyBannerTech} key={id}>
                <img
                  className={styles.technologyBannerImage}
                  src={link}
                  alt={alt_text ? alt_text : `${name} logo`}
                  width={width}
                  height={height}
                  {...imageAttrs}
                />
                <Heading
                  priority={5}
                  className={styles.technologyBannerHeading}
                >
                  {name}
                </Heading>
              </Flex>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

const TechnologyBanner: FunctionComponent<ITechnologyBannerProps> = (
  {
    ...props
  },
) => (
  <StaticQuery
    data-test="component-technology-banner-query"
    query={query}
    render={queryProps => <PureTechnologyBanner {...queryProps} {...props} />}
  />
);

const query = graphql`
  {
    allWordpressAcfOptions {
      edges {
        node {
          options {
            technology {
              name
              logo {
                id
                alt_text
                link
                media_details {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TechnologyBanner;
