import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button from 'src/components/molecules/Buttons/Button';
import Heading from 'src/components/shared/Heading/Heading';
import styles from './Service.module.scss';

import { IconName } from '@fortawesome/fontawesome-svg-core';

export interface IServiceProps {
  id: string | number;
  wordpress_id: string | number;
  icon: IconName;
  slug: string;
  heading: string;
  subheading?: string;
  className?: string;
}

const Service: FunctionComponent<Partial<IServiceProps>> = ({
  icon,
  heading,
  subheading,
  className,
  slug,
}) => {
  const link = `/services/${slug}`;

  return (
    <div
      data-test="component-service"
      className={classNames(styles.service, className)}
    >
      <Link to={link} className={styles.serviceLink}>
        <span className="sr-only">{heading}</span>
      </Link>

      <FontAwesomeIcon icon={['fad', icon]} className={styles.serviceIcon} />

      <Heading priority={3} className={styles.serviceHeading}>
        {heading}
      </Heading>

      {subheading && (
        <Heading priority={5} className={styles.serviceSubheading}>
          {subheading}
        </Heading>
      )}

      <Button
        size={'xs'}
        link={link}
        theme={'tint-psi'}
        className={styles.serviceButton}
      >
        Learn more
      </Button>
    </div>
  );
};

export default Service;
