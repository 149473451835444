import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import Button from 'src/components/molecules/Buttons/Button';
import Service, {
  IServiceProps,
} from 'src/components/molecules/Service/Service';
import Heading from 'src/components/shared/Heading/Heading';
import { IAcfComponentProps } from 'src/utils/interfaces';

import styles from './FeaturedServices.module.scss';

const FeaturedServices: FunctionComponent<Partial<IAcfComponentProps>> = ({
                                                                            module = {},
                                                                            pageTheme = 'tint-alpha',
                                                                          }) => {
  const { contrast, services = [], heading, copy } = module;
  let featuredServices = services;

  if (services.length === 0) {
    featuredServices = [
      {
        id: '1',
        wordpress_id: 1,
        heading: 'Web',
        subheading: 'Design & Development',
        icon: 'browser',
        slug: 'web-design-and-development',
      },
      {
        id: '2',
        wordpress_id: 2,
        heading: 'Design',
        subheading: 'Stand out',
        icon: 'palette',
        slug: 'graphic-design',
      },
      {
        id: '3',
        wordpress_id: 3,
        heading: 'S.E.O',
        subheading: 'Be found',
        icon: 'search',
        slug: 'seo',
      },
      {
        id: '4',
        wordpress_id: 4,
        heading: 'Strategy',
        subheading: 'Set your path to success',
        icon: 'chart-line',
        slug: 'strategy',
      },
    ];
  }

  return (
    <Section
      data-test="component-featured-services"
      contrast={contrast}
      theme={module.theme ? module.theme : pageTheme}
      className={classNames(
        [
          styles.featuredServices,
        ]
      )}
    >
      <Container fluid={false}>
        <Row>
          {(heading || copy) && (
            <>
              <Flex colLg={7} className={'text-left'}>
                {heading && (
                  <Heading
                    className={styles.featuredServicesHeading}
                    priority={2}
                  >
                    {heading}
                  </Heading>
                )}
                {copy && (
                  <p className={styles.featuredServicesCopy}>
                    <span dangerouslySetInnerHTML={{ __html: copy }}/>
                  </p>
                )}
              </Flex>

              <Flex colLg={4} className={'ml-auto display-flex'}>
                <Button
                  caps
                  outline
                  className={styles.featuredServicesButton}
                  link={'/services'}
                >
                  View our services
                </Button>
              </Flex>
            </>
          )}

          {!heading && (
            <>
              <Flex className="text-center">
                <Heading
                  innerHTML={false}
                  priority={2}
                  className={classNames(
                    styles.featuredServicesHeading,
                    'text-center'
                  )}
                >
                  Our Services
                </Heading>
              </Flex>
            </>
          )}
        </Row>
      </Container>

      <Container>
        {featuredServices && (
          <Row className="justify-content-center mt-5">
            {featuredServices.map((service: Partial<IServiceProps>) => (
              <Flex
                colLg={3}
                colMd={6}
                key={service.id}
                className={styles.featuredServicesServiceWrapper}
              >
                <Service
                  {...service}
                  className={styles.featuredServicesService}
                />
              </Flex>
            ))}
          </Row>
        )}
      </Container>
    </Section>
  );
};

export default FeaturedServices;
