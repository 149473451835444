import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import AnchorLink from 'src/components/atoms/AnchorLink/AnchorLink';
import Heading from 'src/components/shared/Heading/Heading';

import styles from './Testimonial.module.scss';

interface ITestimonial {
  className?: string;
  name: string;
  content: string;
  company: {
    post_name: string;
    post_title: string;
  };
}

const Testimonial: FunctionComponent<ITestimonial> = ({
  name,
  content,
  company,
  className,
}) => {
  return (
    <div className={classNames(className, styles.testimonial)}>
      <blockquote className={styles.testimonialQuote}>
        {content}

        <Heading
          priority={4}
          innerHTML={false}
          className={styles.testimonialHeading}
        >
          <span className={styles.testimonialHeadingName}>{name}</span>
          {company && ` - ${company.post_title}`}
        </Heading>

        {company && (
          <AnchorLink
            className={styles.testimonialLink}
            link={`/project/${company.post_name}`}
          >
            View their profile
          </AnchorLink>
        )}
      </blockquote>
    </div>
  );
};

export default Testimonial;
