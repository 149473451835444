import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import Heading from 'src/components/shared/Heading/Heading';
import { IAcfModule } from 'src/utils/interfaces';

import styles from './SimpleHero.module.scss';

interface ISimpleHeroProps {
  module: Partial<IAcfModule>;
  className: string;
  location: Location;
  pageContext: any;
}

const SimpleHero: FunctionComponent<Partial<ISimpleHeroProps>> = ({
  module = {},
  pageContext,
  className,
}) => {
  const { heading, copy } = module;

  return (
    <Section
      theme={module.theme ? module.theme : 'tint-psi'}
      className={classNames(styles.simpleHero, className)}
    >
      <Container fluid={false} className={styles.simpleHeroContainer}>
        <Row>
          <Flex>
            <Heading className={styles.simpleHeroHeading} priority={1}>
              {heading ? heading : pageContext.title}
            </Heading>
            {copy ? (
              <p className={styles.simpleHeroCopy}>{copy}</p>
            ) : pageContext.acf.subtitle ? (
              <p className={styles.simpleHeroCopy}>
                {pageContext.acf.subtitle}
              </p>
            ) : null}
          </Flex>
        </Row>
      </Container>
    </Section>
  );
};

export default SimpleHero;
