import { StaticQuery, graphql } from 'gatsby';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import classNames from 'classnames';

import AnchorLink from 'src/components/atoms/AnchorLink/AnchorLink';
import CarouselArrows from 'src/components/atoms/CarouselArrows/CarouselArrows';

import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import Button from 'src/components/molecules/Buttons/Button';
import Carousel from 'src/components/molecules/Carousel/Carousel';
import ProjectCard from 'src/components/molecules/ProjectCard/ProjectCard';
import Heading from 'src/components/shared/Heading/Heading';
import RenderChildren from 'src/utils/components/RenderChildren/RenderChildren';
import { IAcfModule } from 'src/utils/interfaces';
import { TTheme } from 'src/utils/types';
import styles from './FeaturedProjects.module.scss';

interface IPureFeaturedProjectsProps extends IFeaturedProjectsProps {
  allWordpressWpProjects: any;
}

interface IFeaturedProjectsProps {
  className: string;
  module: Partial<IAcfModule>;
  pageTheme: TTheme;
  showHeader: boolean;
  contrast: boolean;
}

export const PureFeaturedProjects: FunctionComponent<Partial<IPureFeaturedProjectsProps>> =
  memo(({
          className,
          module = {},
          contrast,
          showHeader = true,
          allWordpressWpProjects,
          pageTheme = 'tint-alpha',
        },
       ) => {
    const [state, setState] = useState({ isLoaded: false });

    useEffect(() => {
      setState({ isLoaded: true });
    }, []);

    const projects = [...allWordpressWpProjects.edges];

    const settings = {
      dots: false,
      arrows: true,
      centerMode: true,
      draggable: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <CarouselArrows theme={module.theme} contrast={contrast} next/>,
      prevArrow: <CarouselArrows theme={module.theme} contrast={contrast} prev/>,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            draggable: true,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            infinite: true,
          },
        },
      ],
    };

    if (typeof window !== 'undefined') {
      // console.log('[FeaturedProjects]', projects);
    }

    return (
      <Section
        data-test="component-featured-projects"
        theme={module.theme ? module.theme : pageTheme}
        className={classNames(styles.featuredProjects, className)}
      >
        <Container fluid={false} className={styles.featuredProjectsContainer}>
          <Row>
            <RenderChildren if={showHeader}>
              <Flex colLg={7} className="text-left">
                <Heading
                  innerHTML={false}
                  priority={2}
                  className={styles.featuredProjectsHeading}
                >
                  Featured Projects
                </Heading>
                <p className={styles.featuredProjectsCopy}>
                  We create online experiences that motivate potential clients to interact and make contact, benefiting
                  your
                  business immensely. See who we have helped.
                </p>
              </Flex>

              <Flex colLg={4} className={'ml-auto display-flex'}>
                <Button
                  outline
                  caps
                  link={'/our-work'}
                  className={styles.featuredProjectsButton}
                >
                  View all our work
                </Button>
              </Flex>
            </RenderChildren>

            <RenderChildren if={!showHeader}>
              <Flex className="text-center">
                <Heading
                  innerHTML={false}
                  priority={2}
                  className={classNames(styles.featuredProjectsHeading, 'text-center')}
                >
                  Latest Projects
                </Heading>
              </Flex>
            </RenderChildren>
          </Row>
        </Container>

        <Container className={styles.featuredProjectsContainer}>
          <Row className="mt-4">
            <Flex col={12}>
              <RenderChildren if={state.isLoaded}>
                <Carousel theme={module.theme ? module.theme : pageTheme} settings={settings}>
                  {projects.map((projectNode: any, index: number) => {
                    const { node: project } = projectNode;
                    return (
                      <div
                        key={index}
                        className={styles.featuredProjectsProjectWrapper}
                      >
                        <ProjectCard
                          project={project}
                          showServices={false}
                          shadow={true}
                          className={styles.featuredProjectsProject}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </RenderChildren>
            </Flex>
          </Row>

          <RenderChildren if={!showHeader}>
            <Row className="text-center mt-5">
              <Flex col={12}>
                <AnchorLink
                  className={styles.featuredProjectsButton}
                  link={'/our-work'}
                >
                  View all our work
                </AnchorLink>
              </Flex>
            </Row>
          </RenderChildren>
        </Container>
      </Section>
    );
  });

export const FeaturedProjects: FunctionComponent<Partial<IFeaturedProjectsProps>> = memo(({ ...props }) => (
  <StaticQuery
    data-test="component-featured-projects-query"
    query={query}
    render={queryProps => (
      <PureFeaturedProjects
        {...queryProps}
        {...props}
      />
    )}
  />
));

const query = graphql`
      {
        allWordpressWpProjects(
          filter: { project_services: { elemMatch: { slug: { eq: "featured" } } } }
        ) {
          edges {
            node {
              id
              wordpress_id
              title
              slug
              content
              featured_media {
                ...media
              }
              project_services {
                id
                wordpress_id
                name
                slug
              }
              acf {
                logo {
                  ...media
                }
              }
            }
          }
        }
      }
      
      fragment media on wordpress__wp_media {
        id
        wordpress_id
        alt_text
        source_url
        media_type
        media_details {
          width
          height
        }
      }
    `;

export default FeaturedProjects;
