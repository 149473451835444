import React, { PureComponent } from 'react';
import AllProjects from 'src/components/organisms/AllProjects/AllProjects';
import ContentBlock from 'src/components/organisms/ContentBlock/ContentBlock';
import FeaturedProjects from 'src/components/organisms/FeaturedProjects/FeaturedProjects';
import FeaturedServices from 'src/components/organisms/FeaturedServices/FeaturedServices';
import PageHero from 'src/components/organisms/PageHero/PageHero';
import PressPack from 'src/components/organisms/PressPack/PressPack';
import SimpleHero from 'src/components/organisms/SimpleHero/SimpleHero';
import TechnologyBanner from 'src/components/organisms/TechnologyBanner/TechnologyBanner';
import Testimonials from 'src/components/organisms/Testimonials/Testimonials';
import TextBlock from 'src/components/organisms/TextBlock/TextBlock';
import { mapOverACFComponents } from 'src/utils';
import RenderChildren from 'src/utils/components/RenderChildren/RenderChildren';
import { IAcfModule } from 'src/utils/interfaces';
import { TTheme } from 'src/utils/types';

import InlineQuote from '../../organisms/InlineQuote/InlineQuote';
import ImageBlock from '../../organisms/ImageBlock/ImageBlock';
import CtaBanner from '../../organisms/CtaBanner/CtaBanner';

interface IAcfComponentsProps {
  component: Partial<IAcfModule>;
  pageContext?: any;
  location?: Location;
  pageTheme: TTheme | string;
}

export class AcfComponents extends PureComponent<IAcfComponentsProps> {
  render() {
    const { component: rootComponent, location, pageTheme, pageContext } = this.props;
    const component = mapOverACFComponents(rootComponent);
    const componentName = component.__typename;

    const defaultProps = {
      'data-test': `hoc-acf-${componentName}`,
      module: component,
      pageTheme,
    };

    return (
      <>
        <RenderChildren if={componentName === 'page_hero'}>
          <PageHero
            {...defaultProps}
            location={location}
            pageContext={pageContext}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'text_block'}>
          <TextBlock
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'simple_hero'}>
          <SimpleHero
            {...defaultProps}
            pageContext={pageContext}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'image_block'}>
          <ImageBlock
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'inline_quote'}>
          <InlineQuote
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'cta_banner'}>
          <CtaBanner
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'content_block'}>
          <ContentBlock
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'press_pack'}>
          <PressPack
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'all_projects'}>
          <AllProjects
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'featured_services'}>
          <FeaturedServices
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'featured_projects'}>
          <FeaturedProjects
            {...defaultProps}
            showHeader={false}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'technology_banner'}>
          <TechnologyBanner
            {...defaultProps}
          />
        </RenderChildren>

        <RenderChildren if={componentName === 'testimonials'}>
          <Testimonials
            {...defaultProps}
          />
        </RenderChildren>

      </>
    );
  }
}
