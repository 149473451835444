import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { AcfComponents } from 'src/components/shared/AcfComponents/AcfComponents';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import { sanitizeEncodedChar } from 'src/utils';
import { IAcfModule, IMenuOpen, IPageContext } from 'src/utils/interfaces';
import styles from './PageTemplate.module.scss';

export class UnconnectedPageTemplate extends PureComponent<IPageContext> {
  render() {

    const { pageContext, location } = this.props;
    const { acf } = pageContext;
    const { page_theme: pageTheme, components_page: components } = acf;
    const pageTitle = sanitizeEncodedChar(pageContext.title);

    return (
      <CoreLayout
        location={location}
        title={pageTitle}
        description={pageContext.yoast_meta && pageContext.yoast_meta.yoast_wpseo_metadesc}
        data-test="template-page"
        className={classNames([
          styles.page,
          {
            [`page__${pageContext.slug && pageContext.slug.replace('_', '-')}`]: pageContext.slug,
          },
        ])}
      >
        {components && components.map(
          (component: IAcfModule, index: number) => {
            return (<AcfComponents
              key={index}
              component={component}
              pageContext={pageContext}
              location={location}
              pageTheme={pageTheme}
            />);
          })
        }
      </CoreLayout>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ core: { isMenuOpen } }: IMenuOpen) => ({
  isMenuOpen,
});

export default connect(
  mapStateToProps,
  null,
)(UnconnectedPageTemplate);
