import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button from 'src/components/molecules/Buttons/Button';
import Heading from 'src/components/shared/Heading/Heading';
import WYSIWYG from 'src/components/shared/WYSIWYG/WYSIWYG';
import { mapPageIdToPage } from 'src/utils';
import { EPosition } from 'src/utils/enums';
import { IAcfComponentProps } from 'src/utils/interfaces';

import Section from '../../grid/Section/Section';
import Row from '../../grid/Row/Row';
import Container from '../../grid/Container/Container';
import Flex from '../../grid/Flex/Flex';

import styles from './ContentBlock.module.scss';

const ContentBlock: FunctionComponent<IAcfComponentProps> = ({
  module,
  pageTheme = 'tint-alpha',
}) => {
  const {
    heading,
    copy,
    position,
    cta,
    cta_text,
    cta_link,
    cta_theme,
    highlight,
    image,
    contain_image,
    theme,
  } = module;

  const getCta = () => {
    let link = cta_link ? `/${cta_link.post_name}` : `/contact`;

    if (cta_link) {
      if (cta_link.post_parent) {
        link = `/${mapPageIdToPage(cta_link.post_parent)}/${
          cta_link.post_name
        }`;
      }
    }

    if (cta) {
      return (
        <Button
          link={link}
          theme={cta_theme ? cta_theme : pageTheme}
          className={classNames(styles.contentBlockButton, 'mt-3')}
          caps
          icon={{
            weight: 'far',
            name: 'link',
          }}
          size={'md'}
        >
          {cta_text}
        </Button>
      );
    }
    return null;
  };

  const imageAttrs = {
    loading: 'lazy'
  };

  return (
    <Section
      data-test="component-content-block"
      theme={theme ? theme : pageTheme}
      className={classNames(
        `highlight--${highlight ? highlight : pageTheme}`,
        styles.contentBlock,
        {
          [styles[`content-block--${position}`]]: position,
        }
      )}
    >
      <Container>
        {position === EPosition.CENTER && (
          <>
            <Row>
              <Flex
                colMd={9}
                colLg={7}
                className={classNames(
                  'text-center mx-auto',
                  styles.contentBlockContent,
                  styles.contentBlockContentWithImage
                )}
              >
                {heading && (
                  <Heading priority={2} className={styles.contentBlockHeading}>
                    {heading}
                  </Heading>
                )}

                <WYSIWYG
                  className={styles.contentBlockCopy}
                  dangerouslySetInnerHTML={{ __html: copy }}
                />

                {getCta()}
              </Flex>
            </Row>

            <Row>
              <Flex
                colMd={10}
                colLg={10}
                className={classNames('text-center mx-auto')}
              >
                {image && (
                  <img
                    src={image.source_url}
                    alt={image.alt_text}
                    width={
                      image.media_details.width && image.media_details.width
                    }
                    height={
                      image.media_details.height && image.media_details.height
                    }
                    className={classNames(styles.contentBlockImage)}
                    {...imageAttrs}
                  />
                )}
              </Flex>
            </Row>
          </>
        )}

        {position === EPosition.LEFT && (
          <Row>
            <Flex colLg={6} className={classNames(styles.contentBlockContent)}>
              {heading && (
                <Heading priority={2} className={styles.contentBlockHeading}>
                  {heading}
                </Heading>
              )}

              <WYSIWYG
                className={styles.contentBlockCopy}
                dangerouslySetInnerHTML={{ __html: copy }}
              />

              {getCta()}
            </Flex>

            {image && (
              <Flex
                colLg={6}
                className={classNames(styles.contentBlockBackgroundImage, {
                  [styles.contentBlockBackgroundImageContain]: contain_image,
                })}
                style={{ backgroundImage: `url(${image.source_url})` }}
              />
            )}
          </Row>
        )}

        {position === EPosition.RIGHT && (
          <Row>
            {image && (
              <Flex
                colLg={6}
                className={classNames(styles.contentBlockBackgroundImage, {
                  [styles.contentBlockBackgroundImageContain]: contain_image,
                })}
                style={{ backgroundImage: `url(${image.source_url})` }}
              />
            )}

            <Flex colLg={6} className={classNames(styles.contentBlockContent)}>
              {heading && (
                <Heading priority={2} className={styles.contentBlockHeading}>
                  {heading}
                </Heading>
              )}

              <WYSIWYG
                className={styles.contentBlockCopy}
                dangerouslySetInnerHTML={{ __html: copy }}
              />

              {getCta()}
            </Flex>
          </Row>
        )}
      </Container>
    </Section>
  );
};

export default ContentBlock;
