import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { TOnClick, TTheme } from 'src/utils/types';

import styles from './CarouselArrows.module.scss';

interface ICarouselArrowsProps {
  className: string;
  style: any;
  onClick: TOnClick;
  theme: TTheme;
  contrast: boolean;
  prev: boolean;
  next: boolean;
}

const PrevArrow: FunctionComponent<Partial<ICarouselArrowsProps>> = ({
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames(className, styles.carouselArrows)}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={['fal', 'chevron-left']} />
    </div>
  );
};

const NextArrow: FunctionComponent<Partial<ICarouselArrowsProps>> = ({
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames(className, styles.carouselArrows)}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={['fal', 'chevron-right']} />
    </div>
  );
};

const CarouselArrows: FunctionComponent<Partial<ICarouselArrowsProps>> = ({
  prev,
  next,
  theme,
  className,
  contrast,
  ...props
}) => {
  const getClasses = (): string => {
    return classNames(className, {
      [styles.themeTintAlpha]: theme === 'tint-alpha' || contrast,
      [styles.themeTintBeta]: theme === 'tint-beta' || !contrast,
    });
  };

  const getArrow = () => {
    if (prev || next) {
      return (
        <>
          {prev && (
            <PrevArrow
              data-test="component-carousel-arrows"
              className={getClasses()}
              {...props}
            />
          )}
          {next && (
            <NextArrow
              data-test="component-carousel-arrows"
              className={getClasses()}
              {...props}
            />
          )}
        </>
      );
    }

    return null;
  };

  return getArrow();
};

export default CarouselArrows;
